.bg-BadgeNetzwerk {
    background-color: #555;
  }
  
  .bg-BadgeProzessautomation {
    background-color: #3080ee;
  }
  
  .bg-BadgeSicherheit {
    background-color: #fde052;
    color: #000 !important
  }
  
  .bg-BadgeSteuerung {
    background-color: rgb(225 24 0);
  }
  
  .bg-BadgeKI {
    background-color: rgb(255 146 0);
  }
  
  .bg-BadgeSensorik {
    background-color: rgb(73 183 40);
  }

  .bg-BadgeRobotik {
    background-color: rgb(25, 37, 194);
  }

  .bg-BadgeDigital {
    background-color: rgb(184, 140, 20);
  }

  .bg-BadgeIoT {
    background-color: rgb(47, 141, 68);
  }
  