/* bootsrap variables */
$font-family-sans-serif: 'Inter', sans-serif;
$font-size-base: 1rem;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;

/* custom variables */
$rbs-link-color: #111;
$rbs-link-color-active: #fc790d;
$rbs-link-color-hover: #333;
$rbs-newsletter-button-hover: #444;
$rbs-footer-link-color: #9d9d9d;
$rbs-footer-link-color-active: #fff;

$rbs-textlink-color: #ff6600;
$rbs-textlink-color-hover: #212529;
$rbs-body-color: #212529;

h1,
.h1 {
  font-weight: 600 !important
}

strong {
  font-weight: 600 !important
}

main p a,
.FormTCheckLabelSmall .form-check-label a,
.accordion-body ul a {
  color: $rbs-textlink-color;
  text-decoration: none;
}

main p a:hover,
.FormTCheckLabelSmall .form-check-label a:hover,
.accordion-body ul a:hover {
  color: $rbs-textlink-color-hover;
  text-decoration: none;
}

main .bg-dark p a:hover {
  color: #fff;
}

main ul li {
  padding-bottom: 0.35rem;
}

#Themen,
#Kontaktmesse,
#Redaktionen,
#MultiPressekonferenz {
  padding-top: 0
}

#Themen .container,
#Redaktionen .container,
#Kontaktmesse .container,
#MultiPressekonferenz .container {
  padding-top: 70px;
}

#Themen .card,
#Themen .card-body {
  padding: 0;
  border-radius: 0;
  border: none;
}

.rbs-themen-bild {
  background: transparent url(../images/home/themen.jpg) repeat fixed;
  background-size: 100% auto;
}

.rbs-redaktionen-bild {
  background: transparent url(../images/rueckblick/redaktionen.jpg) repeat fixed;
  background-size: 100% auto;
}

.FirmenbesuchBubble {
  background: transparent url(../images/fpt-bubble-white.png) center 0 no-repeat;
  background-size: 80% auto;
  min-height: 400px;
}

html {
  max-width: 1920px;
  margin: 0 auto;
  background: #111;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

header {
  text-align: center;
}

section {
  min-height: 300px;
  padding: 70px 0 90px;
}

#Firmenbesuch {
  padding-bottom: 0;
}

.custom-section-grey {
  background: #efefef
}

.custom-section-dark-grey {
  background: #ddd
}

section .container {
  min-height: 400px;
}

#ZuGast,
#ZuGast .container,
#Teilnahme,
#Vortrag,
#Vortrag .container,
#Tisch .container,
#Teilnahme .container {
  min-height: unset;
}

#Teilnehmer {
  min-height: 750px;
}

#Firmenteilnehmer {
  min-height: 700px;
}

.rbsLogoSlider {
  min-height: 288px;
}

#ZuGast {
  min-height: unset;
}

#ZuGast,
#Teilnahme {
  padding-bottom: 0 !important
}

#Vortrag.scrollIntoViewFix,
#Bilder.scrollIntoViewFix {
  padding-top: 100px !important;
}

#Teilnahme .tab-content {
  background: #fff;
}

.TeilnahmeTab .nav-tabs {
  display: block !important;
  border-bottom: none !important
}

.TeilnahmeTab button {
  border: none !important;
  background: none !important;
  padding-bottom: .75rem;
}

.TeilnahmeTab .nav-link {
  color: $rbs-link-color;
}

.TeilnahmeTab .nav-link:hover {
  color: $rbs-link-color;
}

.TeilnahmeTab .nav-link.active {
  color: $rbs-link-color-active !important;
}

.TeilnahmeTab .nav-item {
  padding-bottom: 0;
}

.TeilnahmeTab .tab-pane {
  padding: 10px 0 30px;
}

ul.plus,
ul.minus {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.plus li,
ul.minus li {
  padding-bottom: 10px;
  padding-left: 30px;
  background-size: 20px 20px;
  background-position: 6px 3px;
  background-repeat: no-repeat;
}

ul.plus li {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23ff6600' viewBox='0 0 16 16'><path d='M8,4c0.3,0,0.5,0.2,0.5,0.5v3h3C11.8,7.5,12,7.7,12,8c0,0.3-0.2,0.5-0.5,0.5h-3v3C8.5,11.8,8.3,12,8,12 c-0.3,0-0.5-0.2-0.5-0.5v-3h-3C4.2,8.5,4,8.3,4,8c0-0.3,0.2-0.5,0.5-0.5h3v-3C7.5,4.2,7.7,4,8,4z'/></svg>");
}

ul.minus li {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'><path d='M4,8c0-0.3,0.2-0.5,0.5-0.5h7C11.8,7.5,12,7.7,12,8c0,0.3-0.2,0.5-0.5,0.5h-7C4.2,8.5,4,8.3,4,8z'/></svg>");
}

.scrollIntoViewFix {
  margin-top: -101px !important;
  padding-top: 160px !important;
}

#Homeslider,
#Bilder {
  padding: 0;
}

#Vortrag,
#Tisch {
  padding-top: 0;
}

#Videos,
#PreiseKonditionen {
  background: #555 !important;
  color: #fff;
}

#Bilder {
  background: #ddd;
}

.BilderCol {
  position: relative
}

.BilderSpinner {
  position: absolute;
  top: 45%;
  left: 45%
}

#Bilder .container,
#Bilder .container-fluid {
  position: relative;
}

#Bilder .ImageCaretRight,
#Bilder .ImageFlickrLogo {
  margin-top: -4px;
}

.SubNavigationTeaserButton,
#Bilder .ImageCaretRight {
  margin-top: -3px;
}

.rbs-bilder-button {
  padding: 25px 0 !important;
  width: 100%;
  border-radius: 0 !important;
  font-size: 1.5rem;
}

span {
  &.dangerText {
    color: #dc3545;
  }

  &.infoText {
    color: #17a2b8;
    font-weight: bold;
  }
}

header .nav-link {
  margin-left: 15px;
}

#Top.navbar {
  background-color: #fff;
}

.navbar-nav .nav-link {
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.025rem;
  color: $rbs-link-color;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: $rbs-link-color-active !important
}

.ImageFptLogo {
  width: 270px;
  height: auto;
}

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-font-size: 1.3rem;
$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: 0;
$navbar-toggler-transition: none;

$navbar-light-toggler-border-color: none;
$navbar-dark-toggler-border-color: none;

.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 3 14 14'><path d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'/></svg>") !important;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-2 2 18 18'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/></svg>") !important;
}

.subnav .custom-icon-fpt-logo .nav-link {
  padding-top: 12px !important;
  padding-bottom: 0 !important
}

.subnav .custom-icon-fpt-logo .nav-link svg {
  fill: #fff
}

.subnav .custom-icon-fpt-logo .nav-link:hover svg {
  fill: $rbs-link-color-active
}

.subnav {
  background-color: #333;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.subnav .nav-link {
  color: #fff !important;
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
  border-radius: 0 !important;
  font-size: .85rem;
}

.subnav .nav-link.active,
.subnav .nav-link:hover {
  background-color: $rbs-link-color-active !important;
  color: #fff !important;
}

.subnav .custom-icon-fpt-logo .nav-link {
  background-color: transparent !important
}

footer {
  background-color: #333;
  color: #ddd;
}

.top-link {
  // content:'';
  display: block;
  text-align: center;
  width: 50px;
  height: 45px;
  background-color: #333;
  margin: -50px auto 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.top-link svg {
  opacity: 0.5;
  margin-top: 12px;
}

.top-link:hover svg {
  opacity: 1;
  // background-color:#fc790d;
}

.rbs-footer-col-about li {
  padding: 0 0 5px;
}

.rbs-footer-col-about a {
  color: $rbs-footer-link-color;
  text-decoration: none;
}

.rbs-footer-col-about a:hover {
  color: $rbs-footer-link-color-active;
}

.rbs-footer-col-about .btn {
  color: #ddd;
}

.btn-rbs-button-orange,
.btn-primary.rbs-button-group {
  background-color: $rbs-link-color-active !important;
  border-color: $rbs-link-color-active !important;
  color: #fff !important
}

.btn-rbs-button-orange-inverse {
  background-color: $rbs-link-color-hover !important;
  color: #fff !important;
  border-color: $rbs-link-color-hover !important;
}

.rbs-action-buttons {
  min-width: 340px;
  font-weight: 300 !important;
}

.btn-rbs-button-orange:hover,
.btn-rbs-button-orange-inverse:focus,
.btn-primary.rbs-button-group:hover  {
  background-color: $rbs-link-color-hover !important;
  border-color: $rbs-link-color-hover !important;
}

.btn-rbs-button-orange-inverse:hover,
.btn-rbs-button-orange:focus {
  background-color: $rbs-link-color-active !important;
  border-color: $rbs-link-color-active !important;
}

.footer-logo a {
  opacity: 0.5;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-bottom: 25px;
}

.footer-logo {
  opacity: 1;
  outline: none;
}

.footer-logo a:hover,
.footer-logo a:focus {
  opacity: 1;
  outline: none;
}

.rbs-footer-links li {
  float: left;
  padding: 0 15px 0 0;
}

.rbs-footer-links li a {
  text-decoration: none;
  color: $rbs-footer-link-color
}

.rbs-footer-links li a:hover {
  color: #fff
}

.rbs-footer-copyright.small {
  font-weight: 200;
}

.tipps-logo {
  width: 140px;
  height: auto !important;
}

.automationline-logo {
  width: 260px;
  height: auto !important;
  ;
}

.newsletter-button {
  display: none !important
}

.Paragraph b {
  display: block !important;
  margin-top: 25px !important;
  margin-bottom: -15px !important;
}

.info-teaser {
  display: none !important
}

.TeilnahmeBild {
  background-size: 100% auto;
  -webkit-mask-image: url('../images/home/fpt-logo-mask.svg');
  mask-image: url('../images/home/fpt-logo-mask.svg');
}

.CardFotoOverlay {
  background-color: #000000aa;
  color: #fff;
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  padding: 15px 20px 5px;
  width: 100%;
}

.CardWrapper {
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 10px;
}

.CardWrapper .card {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 228px;
  transition: all 0.3s ease-in-out 0s;
  border-radius: 0;
  border: none
}

.CardWrapper .card-header {
  background-color: #333;
  color: #fff;
  border-radius: 0 !important;
  border: none;
  transition: all 0.15s ease-in-out 0s;
}

.CardWrapper .card-footer {
  background-color: #fff;
  border-radius: 0 !important;
  border: none;
  padding-bottom: 20px;
}

.CardWrapper .card-footer .btn {
  min-width: 150px !important
}

.CardWrapper .card-header:hover {
  background-color: $rbs-link-color-active;
  cursor: pointer;
}

.CardWrapper.active .card {
  top: 0;
}

.FotosCardwrapper{
  cursor: pointer;
  transition: all 0.25s;
  transform: scale(0.95);
}
.FotosCardwrapper:hover {
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,.5);
  transform: scale(1);
}
.FotosCardwrapper img {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  // filter: grayscale(100%);
  // opacity: .75;  
}
.item.zoom {
  background-color: blue;
}

.ToggleButton {
  display: block;
  width: 20px;
  height: 25px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M7.6,4.6c0.2-0.2,0.5-0.2,0.7,0c0,0,0,0,0,0l6,6c0.2,0.2,0.2,0.5,0,0.7s-0.5,0.2-0.7,0L8,5.7l-5.6,5.6 c-0.2,0.2-0.5,0.2-0.7,0s-0.2-0.5,0-0.7L7.6,4.6z'/%3E%3C/svg%3E");
  margin-bottom: 10px;
}

.active .ToggleButton {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1.6,4.6c0.2-0.2,0.5-0.2,0.7,0c0,0,0,0,0,0L8,10.3l5.6-5.6c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7l-6,6 c-0.2,0.2-0.5,0.2-0.7,0c0,0,0,0,0,0l-6-6C1.5,5.2,1.5,4.8,1.6,4.6C1.6,4.6,1.6,4.6,1.6,4.6z'/%3E%3C/svg%3E%0A");
}

#Stimmen {
  // margin-bottom: -80px;
  min-height: 800px;
}

.HomesliderItem,
#HomesliderDanke {
  background-position: 0 4000px;
  background-repeat: no-repeat;
}

#Stimmen .carousel-indicators {
  margin-bottom: -80px;
}

#Stimmen .carousel-item {
  background-color: transparent;
}

.StimmenWrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.StimmenWrapper h1 {
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  font-style: italic;
  border-radius: 16px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0;
  text-align: left;
}

.StimmenWrapper p {
  display: inline-block;
  text-align: left;
  max-width: 700px;
  min-height: 40px;
  padding: 20px 50px 0 30px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: $$$/GeneralStr/196=Adobe Illustrator 27.6.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' style='enable-background:new 0 0 30 30;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='0,30 0,0 30,0 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 0;
  background-size: 40px 40px;
  margin-right: 15px;
}

$carousel-indicator-width: 20px;
$carousel-indicator-height: 20px;
$carousel-indicator-spacer: 6px;

.carousel-indicators button {
  border-radius: 20px;
}

.carousel-indicators [data-bs-target] {
  border-top: none !important;
  border-bottom: none !important;
}

#Homeslider .ImageFlickrLogo {
  margin-top: -3px;
  margin-left: 5px;
}

.ImageRueckblick {
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.ImagePlaceholder {
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: .9
}

.VideoImageButton .ImagePlayButton {
  opacity: .35
}

.VideoImageButton:hover .ImagePlayButton {
  opacity: .85;
}

.VideoImageButton:hover .ImagePlaceholder {
  opacity: 1;
}

.VideoCard {
  max-width: 580px;
}

.VideoCard p {
  font-weight: 300;
}

.text-bg-dark.VideoCard {
  max-width: 580px;
  background: #222 !important
}

.ImagePlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -80px 0 0 -40px
}

.ImageAnmeldung {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 center;
  transition: all 0.5s;
}

.ImageVortrag,
.ImageTisch {
  background-size: 0 0;
}

#PreiseKonditionen .lead {
  font-weight: 200;
}

#PreiseKonditionen p {
  font-weight: 300;
}

#PreiseKonditionen .card {
  border-radius: 8px !important;
}

#PreiseKonditionen .card-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  min-height: 110px;
}

#PreiseKonditionen .card-footer {
  border: none;
  background-color: transparent;
  text-align: center;
}

#PreiseKonditionen .card-body,
#PreiseKonditionen .card-body table {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

#PreiseKonditionen .card-body table td {
  padding: 15px 20px;
  border: none;
  line-height: 1.425em !important
}

#PreiseKonditionen .card-title {
  background-color: $rbs-link-color-active;
  color: #fff;
}

form {
  background: #dfdfdf;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  height: auto !important;
}

label {
  font-weight: 500;
}

.FormTCheckLabelSmall {
  margin-top: 3px;
}

.FormTextSmall,
.FormTCheckLabelSmall .form-check-label {
  font-size: 0.875rem;
  line-height: 1.25rem !important;
  font-weight: 400 !important
}

textarea {
  min-height: 150px !important
}

#InfosDownloads .card-header {
  background-color: #333;
  color: #fff;
}

#InfosDownloads .card-header h4 {
  font-weight: 400;
}

.DownloadCardBody {
  background-color: #ccc;
}

.programm-wrapper:hover {
  cursor: pointer;
}

.programm-wrapper div {
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: center;
  padding: 20px;
}

.programm-white {
  background-color: #fff;
}

.programm-orange {
  background-color: $rbs-link-color-active;
  color: #fff;
  padding: 35px 10px 45px !important
}

.programm-black {
  background-color: #333;
  color: #fff;
  padding: 35px 10px 45px !important
}

.ProgrammDetailsButtonCaret {
  fill: $rbs-body-color;
  margin-top: -4px;
}

.programm-wrapper:hover .programm-link {
  background-color: #ccc;
}

.btn-lg .ImageFiletypePdf {
  width: 20px;
  height: 20px;
  margin-top: -4px;
}

.btn-lg {
  padding-top: 10px !important;
  padding-bottom: 12px !important
}

.programm-wrapper div .ImageGreenCircle {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.modal-header--sticky {
  position: sticky;
  top: -5px;
  background-color: inherit;
  z-index: 1055;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
  padding: 17px 15px 10px;
}

.RbsModal .modal-header {
  background-color: $rbs-link-color-active;
  color: #fff;
  padding: 10px 15px;
}

.RbsModal .btn-close {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23fff' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath d='M9.4,8l4.8-4.8c0.2-0.2,0.3-0.4,0.3-0.7S14.4,2,14.2,1.8c-0.4-0.4-1-0.4-1.4,0L8,6.6L3.2,1.8c-0.4-0.4-1-0.4-1.4,0 c-0.4,0.4-0.4,1,0,1.4L6.6,8l-4.8,4.8c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L8,9.4l4.8,4.8c0.2,0.2,0.4,0.3,0.7,0.3 s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7L9.4,8z'/%3E%3C/svg%3E");
  width: 20px !important;
  height: 20px !important
}

.RbsModal .modal-title.h4 {
  font-weight: 400;
  font-size: 1.1rem;
}

.RbsModal .modal-body {
  font-size: 1rem;
}

.RbsProgrammModal table {
  width: 100%
}

.RbsProgrammModal .badge {
  font-weight: 400 !important
}

.RbsProgrammModal td {
  padding-top: 16px !important;
  padding-bottom: 18px !important;
}

.ProgrammTime,
.ProgrammEvent h1 {
  font-size: 1.1rem !important;
  line-height: 1.5rem;
}

.ProgrammTime {
  text-align: right;
  width: 75px;
}

.ProgrammHeadline {
  color: #000;
}

.ProgrammEvent h1 {
  margin: 0;
  padding: 0;
  font-weight: 400 !important
}

.ProgrammToggler h1 {
  font-weight: 600 !important;
}

.ProgrammGreyRow {
  background-color: #ddd;
}

.ProgrammBlackRow {
  background-color: #333;
  color: #fff;
  font-weight: 200 !important;
}

.ProgrammBlackRow h1 {
  font-weight: 200 !important;
}


.ProgrammCompany,
.ProgrammToggler {
  color: $rbs-textlink-color;
  text-decoration: none;
}

.ProgrammCompany {
  display: block;
  margin-bottom: 2px;
  text-decoration: underline;
}

.ProgrammToggler:hover {
  cursor: pointer;
}

.ProgrammCompany:hover,
.ProgrammToggler:hover,
.ProgrammToggler:hover .ProgrammCompany {
  color: $rbs-textlink-color-hover
}

.ProgrammToggler.active,
.ProgrammToggler.active .ProgrammCompany {
  color: $rbs-textlink-color-hover
}

.ProgrammToggler.active:hover .ProgrammCompany,
.ProgrammToggler.active:hover {
  color: $rbs-textlink-color
}

.ProgrammSpeaker {
  font-style: italic;
  display: block;
  margin-bottom: 4px;
  color: $rbs-textlink-color-hover
}

.ProgrammToggler h1::after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ff6600' class='bi bi-caret-down-fill' viewBox='0 -2 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  margin-left: 4px;
}

.ProgrammToggler.active h1::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23212529' class='bi bi-caret-up-fill' viewBox='0 -2 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
}

.ProgrammToggler:hover h1::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23212529' class='bi bi-caret-down-fill' viewBox='0 -2 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
}

.ProgrammToggler.active:hover h1::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ff6600' class='bi bi-caret-up-fill' viewBox='0 -2 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
}

.ProgrammContent p {
  margin-top: 5px;
  margin-bottom: 0;
  padding: 0;
}

.ProgrammContent .btn {
  margin-top: 15px;
}

.ImageRedaktionLogo {
  max-width: 180px;
  height: auto;
  max-height: 50px;
}

.RedaktionCard {
  border: none !important;
  padding: 0 !important;
}

.RedaktionImage {
  height: 50px;
  margin-bottom: 15px;
}

.RbsRedaktionenLink {
  text-decoration: none !important;
  color: $rbs-textlink-color-hover
}

.RbsRedaktionenLink:hover {
  color: $rbs-textlink-color
}

.RbsDropdown {
  margin-top: 8px;
}

.programm-wrapper div table,
.programm-wrapper div table .ProgrammContent {
  text-align: left !important;
}

.programm-wrapper div table .ProgrammContent {
  font-size: 0.9rem !important
}

.StimmenWrapper h1 {
  line-height: 1.5em;
}

.PageNotFound h1 {
  font-weight: 400 !important
}

.PageNotFound {
  background: #000;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  padding-bottom: 0;
}

.slick-dots li button:before {
  font-size: 18px !important
}

.modern-react-captcha__captchaText {
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  display: inline-block;
  text-align: center;
  min-height: 58px;
  min-width: 100px;
}

.modern-react-captcha__reloadBtn {
  border: none;
  background: transparent;
}

.modern-react-captcha__inputField {
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  display: block;
}

.FormularAlert {
  position: fixed !important;
  bottom: 5px;
  width: 360px;
  left: 10px;
  z-index: 1000;
}

#Homeslider .carousel-indicators button {
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
}

#Homeslider .carousel-control-prev-icon,
#Homeslider .carousel-control-next-icon {
  margin-top: 100px;
}

#Homeslider .carousel-control-prev,
#Homeslider .carousel-control-next {
  align-items: start
}

#Homeslider .carousel-indicators .active {
  background-color: #fc790d !important;
}

.HomeSliderHeadlineSmall {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 80px;
  margin-left: -170px;
  font-size: 1.5rem !important;
}

.rbs-accordion {  
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px !important;
  border:none !important;
  background-color: transparent !important
}

.rbs-accordion .accordion-item {  
  border-color:#c0c0c0 !important;
}

.rbs-accordion .accordion-button,
.btn-secondary.rbs-button-group {
  color: $rbs-link-color !important;
  background-color: #eee !important;
  font-weight:500;
}

.btn-secondary.rbs-button-group {
  border-color:#c0c0c0 !important;
}

.rbs-tisch-header .accordion-button {
  background-color: #ddd !important;
  
}

.rbs-vortrag-header .accordion-button {
  background-color: #cfcfcf !important;
}

.rbs-accordion .accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: $rbs-link-color-active !important;
}

.rbs-accordion .accordion-button:not(.collapsed):hover,
.rbs-accordion .accordion-button:hover,
.btn-secondary.rbs-button-group:hover {
  color: #fff !important;
  background-color: $rbs-link-color-hover !important;
}

.rbs-accordion .accordion-button:not(.collapsed)::after,
.rbs-accordion .accordion-button:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.rbs-accordion .accordion-button:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.rbs-redaktionen-slider-item {
  height: 70px !important;
  margin: 0 0 15px 0;
}

.rbs-redaktionen-slider-item-image {
  margin:10px 0 0 0;
  max-height: 50px;
  max-width:200px;
}

.rbs-redaktionen-slider-item-text {
  display: inline-block;
  margin:10px 0 0 0;
  width:240px;
  padding:0 10px 5px;
  height:50px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  line-height: normal;
}

.rbs-redaktionen-slider-item-text span {
  display: inline-block;
  margin:0 auto;
}

.rbsLogoSlider .slick-dots {
  bottom:-50px !important
}

// remove xxl grid-breakpoints 1400px
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// );

// change xxl container-max-widths from 1320px to 1141px
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1141px
);

// #####################################
// #####################################
// #####################################
// #####################################
// #####################################


// sm
@media (min-width: 576px) {

  .HomeSliderHeadlineSmall {
    width: 500px;
    top: 80px;
    margin-left: -210px;
    font-size: 1.75rem !important;
  }

}

// #####################################
// #####################################
// #####################################
// #####################################
// #####################################


// md
@media (min-width: 768px) {
  .ImageFptLogo {
    width: 280px;
  }

  .TeilnahmeTab .nav-tabs {
    display: flex !important
  }

  .rbs-bilder-button {
    padding: 15px 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 280px;
    margin-left: -140px;
    margin-top: -30px;
    border-radius: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  h1,
  .h1 {
    font-size: 2rem !important
  }

  .FirmenbesuchBubble {
    background-size: 450px auto;
    min-height: 400px;
  }

  .FormularAlert {
    width: 500px;
    left: 60px;
    z-index: 1000;
  }

  #Homeslider .carousel-control-prev-icon,
  #Homeslider .carousel-control-next-icon {
    margin-top: 160px;
  }

  #Homeslider .carousel-control-prev,
  #Homeslider .carousel-control-next {
    align-items: start
  }

  .HomesliderItem .lead {
    font-weight: 300 !important;
  }

  .HomeSliderHeadlineSmall {
    width: 760px;
    top: 100px;
    margin-left: -280px;
    font-size: 2.5rem !important;
    font-weight: 400 !important;
  }

  .CardWrapper {
    width: 400px;
    height: 400px;
  }
  
  .CardWrapper .card {
    width: 400px;
    height: 400px;
    top: 328px;
  }

}

// #####################################
// #####################################
// #####################################
// #####################################
// #####################################


// lg
@media (min-width: 992px) {
  .ImageFptLogo {
    width: 330px;
  }

  header .nav-link {
    margin-left: initial;
  }

  .subnav .nav-link {
    font-size: initial;
  }

  .footer-logo li {
    float: left;
    margin-bottom: 0;
  }

  .tipps-logo {
    width: 140px;
    height: auto !important;
    margin: 4px 40px 0 0;
  }

  .rbs-logo {
    margin: 0 40px 0 0;
  }

  .automationline-logo {
    width: 240px;
    height: auto !important;
    margin-top: 12px;
  }

  .newsletter-button {
    display: block !important;
    z-index: 1000;
    position: fixed;
    left: -108px;
    bottom: 80px;
    font-size: 15px;
    width: 160px;
    padding-left: 15px !important;
    padding-right: 3px !important;
    padding-bottom: 8px !important;
    transition: all 0.2s ease-in-out 0s !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .newsletter-button svg {
    margin-top: -3px;
  }

  .newsletter-button:hover,
  .newsletter-button:focus {
    left: -3px;
    background-color: $rbs-newsletter-button-hover !important;
    border-color: $rbs-newsletter-button-hover !important;
  }

  .download-link {
    text-align: left !important;
    padding-left: 42px !important;
    padding-top: 12px !important;
    text-decoration: none !important;
    color: $rbs-textlink-color !important;
    font-size: 1.125rem !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='%23000' class='bi bi-filetype-pdf' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 12px 12px;
  }

  .download-link:hover {
    color: $rbs-textlink-color-hover !important;
  }


  .info-teaser {
    display: block !important;
    position: absolute;
    top: 48px;
    right: 0;
    border-radius: 0 !important;
    border-bottom-left-radius: 10px !important;
    font-size: 1.125rem !important;
    padding: 6px 20px 11px !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .info-teaser:hover,
  .info-teaser:focus {
    background-color: $rbs-newsletter-button-hover !important;
    border-color: $rbs-newsletter-button-hover !important;
  }

  .TeilnahmeTab .nav-link {
    border-bottom: 5px solid transparent !important;
  }

  .TeilnahmeTab .nav-link:hover {
    border-bottom: 5px solid $rbs-link-color !important;
  }

  .TeilnahmeTab .nav-link.active {
    border-bottom: 5px solid $rbs-link-color-active !important;
  }

  #ZuGast .container-fluid,
  #Fotos .container-fluid {
    max-width: 900px;
  }

  #ZuGast .card-body,
  #Fotos .card-body {
    min-height: 250px;
  }

  #Homeslider .carousel-item {
    min-height: 700px;
  }

  .HomesliderItem,
  #HomesliderDanke {
    background-position: 0 0;
    background-size: 1600px auto;
  }

  .HomesliderItem {
    background-position: center 0;
  }

  .ImageVortrag,
  .ImageTisch {
    background-position: 0 0;
    background-size: 45% auto;
    background-repeat: no-repeat;
    // transition: all 0.2s ease-in-out 0s;
    min-height: 420px;
  }

  .ImageTisch {
    background-position: right 0;
  }

  .RbsNavPills .nav-item {
    display: inline-block !important;
    margin-top: 6px;
  }

  .RbsNavPills .nav-link {
    color: $rbs-body-color !important;
    margin-right: 10px;
  }

  .RbsNavPills .nav-link.active,
  .RbsNavPills .nav-link.active:focus {
    background-color: $rbs-link-color-hover !important;
    color: #fff !important;
    border-color: $rbs-link-color-hover !important;
  }

  .RbsNavPills .nav-link:hover,
  .RbsNavPills .nav-link:focus,
  .RbsNavPills .nav-link.active:hover {
    background-color: $rbs-link-color-active !important;
    color: #fff !important;
    border-color: $rbs-link-color-active !important;
  }

  .PageNotFound {
    min-height: 500px !important
  }

  #Homeslider .carousel {
    background-color: #111 !important;
  }

  #Homeslider .carousel-indicators {
    bottom: 20px;
  }

  #Homeslider .carousel-control-prev-icon,
  #Homeslider .carousel-control-next-icon {
    margin-top: 0;
  }

  #Homeslider .carousel-control-prev-icon {
    margin-right: 60px;
  }

  #Homeslider .carousel-control-next-icon {
    margin-left: 60px;
  }

  #Homeslider .carousel-control-prev,
  #Homeslider .carousel-control-next {
    align-items: center
  }

  .HomesliderItem {
    color: #fff !important;
  }

  .HomesliderItem h1 {
    font-size: 4rem !important;
    font-weight: 400 !important;
  }

  .HomesliderItem .lead {
    font-size: 2rem !important;
    font-weight: 300 !important;
  }

  .HomesliderItem h1,
  .HomesliderItem .lead {
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  }

}

// #####################################
// #####################################
// #####################################
// #####################################
// #####################################


// xl
@media (min-width: 1200px) {
  .ImageFptLogo {
    width: 370px;
  }

  .navbar-nav .nav-link {
    font-size: 1.075rem;
    padding-right: .75rem !important;
    padding-left: .75rem !important;
  }

  header .container {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .tipps-logo {
    width: 140px;
    height: auto !important;
    margin: 4px 60px 0 0;
  }

  // .rbs-footer-col-about .btn {
  //   margin-left:-15px;
  // }
  .rbs-logo {
    margin: 0 70px 0 0;
  }

  .automationline-logo {
    width: 308px;
    height: auto !important;
    margin-top: 0;
  }

  .rbs-themen-bild {
    background-position: center 48px;
  }

  .TeilnahmeTab .nav-tabs {
    max-width: 1140px;
    margin: 0 auto;
  }

  .TeilnahmeTab button {
    font-size: 1.25rem;
  }

  .CardWrapper {
    width: 350px;
    height: 350px;
  }

  .CardWrapper .card {
    width: 350px;
    height: 350px;
    top: 280px;
  }

  #ZuGast .container-fluid,
  #Fotos .container-fluid {
    max-width: 1220px;
  }

  #ZuGast .card-body,
  #Fotos .card-body {
    min-height: 200px;
  }

  .HomesliderItem,
  #HomesliderDanke {
    background-size: 1900px auto;
  }

  #Homeslider .carousel-item {
    min-height: 700px;
  }

  .ImageVortrag,
  .ImageTisch {
    min-height: 480px;
  }

  .PageNotFound {
    min-height: 600px !important
  }
}

// #####################################
// #####################################
// #####################################
// #####################################
// #####################################

// xxl
@media (min-width: 1400px) {

  main p,
  .StimmenWrapper h1 {
    line-height: 1.55em;
  }

  .ImageVortrag,
  .ImageTisch {
    min-height: 520px;
  }

  .PageNotFound {
    min-height: 700px !important
  }
}


// #####################################
// #####################################
// #####################################
// #####################################
// #####################################

@media (min-width: 1550px) {


  .CardWrapper {
    width: 400px;
    height: 400px;
  }

  .CardWrapper .card {
    width: 400px;
    height: 400px;
    top: 328px;
  }

  #ZuGast .container-fluid,
  #Fotos .container-fluid {
    max-width: 1500px;
  }

  #ZuGast .card-body,
  #Fotos .card-body {
    min-height: 250px;
  }

  #Homeslider .carousel-item {
    min-height: 780px;
  }

  h1,
  .h1 {
    font-size: 2.5rem !important
  }

  .ImageVortrag,
  .ImageTisch {
    min-height: 600px;
  }

  .RbsNavPills .nav-item {
    margin-top: 15px;
  }

  .PageNotFound {
    min-height: 800px !important
  }

}


// #####################################
// #####################################
// #####################################
// #####################################
// #####################################

// 1920
@media (min-width: 1850px) {
  .rbs-themen-bild {
    background-size: 1920px auto;
  }

  .HomesliderItem,
  #HomesliderDanke {
    background-size: 100% auto;
  }

  .ImageVortrag,
  .ImageTisch {
    min-height: 670px;
  }

  .PageNotFound {
    min-height: 900px !important
  }
}